import { Injectable } from '@angular/core';
import { IFCMService } from './ifcm.service';
import { FCMWebService } from './fcm.service.web';
import { FCMMobileService } from './fcm.service.mobile';
import { UserFirestore, UserService } from '../content/user.service';
import { Platform, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { Badge } from '@ionic-native/badge/ngx';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class FCMService {

    specifiedByUser = 'specified-by-user';

    token: string;

    fcmService: IFCMService;

    msgDisableNotifications = 'Notifications are disabled. Please check enviroment configuration.';

    constructor(private fcmWeb: FCMWebService,
                private fcmMobile: FCMMobileService,
                private platform: Platform,
                private userService: UserService,
                private router: Router,
                private afs: AngularFirestore,
                private toastCtrl: ToastController,
                private badge: Badge
    ) {
        this.init(this.platform);
    }

    private async init(platform: Platform) {
        // Validate if it is a mobile device or web
        console.log('platform : ', platform);
        if (platform.is('android')
            || platform.is('ios')) {
            console.log('running on device');
            this.fcmService = this.fcmMobile;
            const hasPermission = await this.badge.hasPermission();
            if (!hasPermission) {
                const permission = await this.badge.requestPermission();
                console.log('Get Permission to Badge', permission);
            }
            window.addEventListener('beforeunload', () => {
                this.clearBadge();
            });

        } else {
            console.log('running in a browser');
            this.fcmService = this.fcmWeb;
        }
    }

    public getToken() {
        return this.fcmService.getToken();
    }
    public onTokenRefresh() {
        return this.fcmService.onTokenRefresh();
    }

    public onNotification() {
        return this.fcmService.onNotification();
    }

    public subscribeToTopic(topic: string) {
        if(topic && topic.length > 0){
            return this.fcmService.subscribeToTopic(topic);
        }
        return Promise.reject();
    }

    public unSubcribeToTopic(topic: string) {
        if(topic && topic.length > 0){        
            return this.fcmService.unSubcribeToTopic(topic);
        }
        return Promise.reject();
    }

    public sendTokenToServer(email: string, currentToken: string) {
        return this.fcmService.sendTokenToServer(email, currentToken);
    }

    public removeTokenFromServer(email: string, currentToken: string) {
        return this.fcmService.removeTokenFromServer(email, currentToken);
    }

    public async registerDeviceToNotifications() {
        console.log('Registering FCM Notifications');
        if (!environment.enableNotifications) {
            console.log(this.msgDisableNotifications);
            return;
        }
        await this.getToken().pipe(first()).subscribe(
            (token) => {
                console.log('Permission granted! Save to the server!', token);
                this.token = token;
                this.fcmService.token = this.token;

                this.fcmService.requestPushPermission().pipe(first()).subscribe((permission) => {
                    console.log('USER ACCEPT NOTIFICATION :', permission);

                    this.userService.getAuthenticatedUserOrg().pipe(first()).subscribe((userAuth) => {
                        if (userAuth) {
                            console.log('USER NOTIFICATIONS:', userAuth);
                            const userId = userAuth[0].uid;
                            const userOrg = [];
                            userAuth.forEach(pwa_org => {
                                userOrg.push(pwa_org.org.id);
                            });
                            this.userService.getUser(userId).pipe(first()).subscribe((user) => {
                                console.log('USER:', user);
                                if (user) {
                                    console.log('UserOrg:', userOrg);
                                    console.log('FCM TOKEN:', this.fcmService.token);
                                    this.sendTokenToServer(userId, token);
                                    this.unSubcribeToTopic(this.specifiedByUser).then(() => {
                                        console.log('user.user_subscription_notification:', user.user_subscription_notification);
                                        const promisesToExecUnsub = [];
                                        const promisesToExecSub = [];
                                        user.user_subscription_notification.forEach(org => {
                                            promisesToExecUnsub.push(this.unSubcribeToTopic(org));
                                        });
                                        if (userOrg.length > 0) {
                                            userOrg.forEach(org => {
                                                promisesToExecSub.push(this.subscribeToTopic(org));
                                            });
                                        } else if (userOrg.length === 0) {
                                            promisesToExecSub.push(this.subscribeToTopic(this.specifiedByUser));
                                        }
                                        return Promise.all(promisesToExecUnsub).then(() => {
                                            return Promise.all(promisesToExecSub);
                                        });
                                    });

                                }
                            },
                            (error) => { console.log('Error : ', error); });
                        }
                    },
                    (error) => { console.log('Error : ', error); });

                },
                (error) => { console.log('Error : ', error); });

                return token;
            },
            (error) => { console.log(error); },
        );

        await this.fcmService.onNotification().subscribe((notificationInfo) => {

            let data = {
                uuid: '',
                title: ''
            };
            if (this.platform.is('android')
                || this.platform.is('ios')) {
                console.log('running on device');
                data = {
                    uuid: notificationInfo.notification_uuid,
                    title : notificationInfo.notification_title
                };
                this.badge.increase(1);
            } else {
                console.log('running in a browser');
                data = {
                    uuid: notificationInfo.data.notification_uuid,
                    title: notificationInfo.data.notification_title
                };
            }

            console.log('NOTIFICATION DATA: ', JSON.stringify(data));
            console.log('NOTIFICATION POST ID: ', data.uuid);

            const toast = this.toastCtrl.create({
                header: 'NEW POST AVAILABLE:',
                message: data.title,
                color: 'secondary',
                translucent: false,
                cssClass: 'new-post-notification',
                buttons: [
                    {
                        side: 'end',
                        icon: 'open',
                        handler: () => {
                            console.log('Open detail');
                            if (this.platform.is('android')
                                || this.platform.is('ios')) {
                                this.clearBadge();
                            }
                            if (data && data.uuid) {
                                this.router.navigate(['/detail/', data.uuid]);
                            }
                        }
                    },
                    {
                        side: 'end',
                        role: 'cancel',
                        icon: 'close',
                        handler: () => {
                            console.log('Cancel clicked');
                        }
                    }
                ]
            });

            toast.then((t) => {
                t.present();
                t.onDidDismiss().then(() =>{
                    this.clearBadge();
                });
            });

        },
        (error) => { console.log('Error : ', error); });

    }

    public clearBadge() {
        if (this.platform.is('android')
            || this.platform.is('ios')) {
            this.badge.clear();
        }
    }

    public unRegisterDeviceToNotifications(): Promise<any> {
        try {
            this.clearBadge();
            if (!environment.enableNotifications) {
                return Promise.resolve(this.msgDisableNotifications);
            }
            return this.userService.getAuthenticatedUserOrg().pipe(first()).toPromise().then((userAuth) => {
                if (userAuth) {
                    console.log('USER UNREGISTER NOTIFICATIONS:', userAuth);
                    const userId = userAuth[0].uid;
                    const userOrg = [];
                    userAuth.forEach(pwa_org => {
                        userOrg.push(pwa_org.org.id);
                    });
                    return this.userService.getUser(userId).pipe(first()).toPromise().then((user) => {
                        console.log('USER:', user);
                        console.log('UserOrg:', userOrg);
                        if (user) {
                            return this.getToken().pipe(first()).toPromise().then(
                                (token) => {
                                    this.token = token;
                                    this.fcmService.token = this.token;
                                    console.log('Getting token to be unregistered', token);
                                    return this.removeTokenFromServer(userId, token).then(() => {
                                        return this.unSubcribeToTopic(this.specifiedByUser).then(() => {
                                            console.log('user.user_subscription_notification:', user.user_subscription_notification);
                                            const promisesToExec = [];
                                            user.user_subscription_notification.forEach(org => {
                                                promisesToExec.push(this.unSubcribeToTopic(org));
                                            });
                                            return Promise.all(promisesToExec).then(() => {
                                                console.log('All unsubscriptions executed.');
                                            });
                                        });
                                    });
                                });
                        }
                    });
                }
            }).catch((e) => {
                return Promise.resolve('ERROR: unregistering notifications');
            });
        } catch (e) {
            return Promise.resolve('ERROR: unregistering notifications');
        }
    }

}
