import { Injectable, NgZone, Inject } from '@angular/core';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { AlertController, ToastController, LoadingController } from '@ionic/angular';
import { Observable, of, pipe } from 'rxjs';
import { switchMap, filter, map, last } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireRemoteConfig, SETTINGS, Parameter } from '@angular/fire/remote-config';
import { User } from './content/user.service';
// Import AUTH_CONFIG, Auth0Cordova, and auth0.js
import { AUTH_CONFIG } from './auth.config';
import Auth0Cordova from '@auth0/cordova';
import * as auth0 from 'auth0-js';
import { auth0Client } from './auth0client';
import { CONSTANTS } from './constants';

//FCM Libs
import { FCMService } from '../services/push-notifications/fcm.service';

declare let cordova: any;

@Injectable()
export class AuthService {
  Auth0;
  Client;
  accessToken: string;
  user: any;
  afs_user: Observable<User>;
  loggedIn: boolean;
  loading = true;
  remoteConfigReady = false;
  authScope = "openid profile offline_access";
  toast: any;
  auth_config = AUTH_CONFIG;
  loadingModal;
  constructor(
    public zone: NgZone,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private safariViewController: SafariViewController,
    private fcmService: FCMService,
    private alertController: AlertController,
    private toastController: ToastController,
    // private remoteConfig: AngularFireRemoteConfig,
    private loadingController: LoadingController,
    @Inject(SETTINGS) private rCSetting: any
  ) {
    // console.log('rCSetting',this.rCSetting);
    console.log("AUTH DEFAULT");
    this.Auth0 = new auth0.WebAuth(this.auth_config);
    this.Client = new Auth0Cordova(this.auth_config);
  }
  initAuthRemote(value: string) {
    console.log("REMOTE CONFIG : ", value);
    this.auth_config = JSON.parse(value);
    this.Auth0 = new auth0.WebAuth(this.auth_config);
    this.Client = new Auth0Cordova(this.auth_config);
    this.remoteConfigReady = true;

    this.handleAuthentication();
  }
  async getUserAuth0Profile(): Promise<any> {
    this.zone.run(() => {
      this.user = JSON.parse(localStorage.getItem("auth0_profile"));
      console.log("getUserAuth0Profile", this.user);
      this.loading = false;
      this.loadingDone();
      this.loggedIn = true;
      // const firebase_token = this.user["https://fenix.group.com/firebase_data"].firebase_token;
    });
  }
  public isAuth0Authenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(
      localStorage.getItem("auth0_expires_at") || "{}"
    );
    const _isAuthenticated = new Date().getTime() < expiresAt;
    // console.log("isAuthenticated", _isAuthenticated);

    return _isAuthenticated;
  }
  async refreshLogin() {
    const verifyAuth0Token = await auth0Client.renewTokens();

    if (verifyAuth0Token) {
      console.log(
        "loggedInThroughRENEW",
        auth0Client.getIdToken(),
        auth0Client.getProfile()
      );
      this.user = auth0Client.getProfile();
      const firebase_token = this.user["https://fenix.group.com/firebase_data"]
        .firebase_token;
      console.log("FIREBASE AUTH TOKEN", this.user, firebase_token);

      this.afAuth.auth
        .signInWithCustomToken(firebase_token)
        .then(async (resp) => {
          console.log("signInWithCustomToken response", resp);
          //Registering FCM Device
          await this.fcmService.registerDeviceToNotifications();
          //// Get auth data, then get firestore user document || null
          this.afs_user = this.afAuth.authState.pipe(
            switchMap((user) => {
              if (user) {
                console.log("authState:", user);
                return this.afs.doc<User>(`user/${user.uid}`).valueChanges();
              } else {
                return of(null);
              }
            })
          );
        })
        .catch(function (err) {
          // eslint-disable-next-line
          console.error("ERROR signInWithCustomToken", err);
        });
    }
  }

  public handleAuthentication(): void {
    this.zone.run(() => {
      console.log("HANDLE AUTH");
      if (
        localStorage.getItem("auth0_isLoggedIn") === "true" &&
        this.isAuth0Authenticated()
      ) {
        this.getUserAuth0Profile();
        return false;
      }
      if (localStorage.getItem("auth0_isLoggedIn") === "true") {
        console.log("renewTokens", this.user);
        // Handle the authentication
        // result in the hash
        this.renewTokens();
        return false;
      } else {
        if (!this.isAuth0Authenticated()) {
          this.presentLogin();
        }
      }

      if (this.Auth0) {
        this.Auth0.parseHash((err, authResult) => {
          console.log("this.Auth0.parseHash");
          this.processAuthResult(err, authResult);
        });
      }
    });
  }
  public localLogin(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem("auth0_isLoggedIn", "true");
    // Set the time that the Access Token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

    localStorage.setItem("auth0_access_token", authResult.accessToken);
    localStorage.setItem("auth0_id_token", authResult.idToken);
    localStorage.setItem("auth0_expires_at", JSON.stringify(expiresAt));

    this.loading = false;
    this.loggedIn = true;
    this.accessToken = authResult.accessToken;
    // Fetch user's profile info
    this.Auth0.client.userInfo(this.accessToken, (err, profile) => {
      if (err) {
        console.log("FETCH USERPROFILE ERROR:", err);
        throw err;
      }
      this.zone.run(() => {
        localStorage.setItem("auth0_profile", JSON.stringify(profile));
        this.user = profile;

        // this.accessToken = localStorage.getItem('auth0_access_token');
        const firebase_token = this.user[
          "https://fenix.group.com/firebase_data"
        ].firebase_token;
        console.log("FIREBASE AUTH TOKEN", this.user, firebase_token);

        this.afAuth.auth
          .signInWithCustomToken(firebase_token)
          .then(async (resp) => {
            console.log("signInWithCustomToken response", resp);

            //Registering FCM Device
            await this.fcmService.registerDeviceToNotifications();

            //// Get auth data, then get firestore user document || null
            this.afs_user = this.afAuth.authState.pipe(
              switchMap((user) => {
                if (user) {
                  console.log("authState:", user);
                  return this.afs.doc<User>(`user/${user.uid}`).valueChanges();
                } else {
                  return of(null);
                }
              })
            );
          })
          .catch(function (err) {
            // eslint-disable-next-line
            console.error("ERROR signInWithCustomToken", err);
          });
      });
    });
  }
  private renewTokens() {
    // console.log("renewTokens");
    localStorage.removeItem("auth0_isLoggedIn");
    this.login();
    // this.Auth0.checkSession({scope: this.authScope, responseType: 'token id_token', redirectUri: AUTH_CONFIG.appRedirect}, (err, authResult) => {
    //     console.log("renewTokens processAuthResult", err, authResult);
    //     this.processAuthResult(err, authResult);
    //   }
    // );
  }
  async processAuthResult(err, authResult): Promise<any> {
    console.log("processAuthResult", err, authResult);
    this.loadingDone();    
    if (err) {
      this.zone.run(() => (this.loading = false));
      console.log("processAuthResult ERROR", err);
      this.presentLogin();
      throw err;
    }
    if (authResult && authResult.accessToken && authResult.idToken) {
      console.log("PROCESS AUTH", err, authResult);
      this.localLogin(authResult);
    } else {
      this.zone.run(() => (this.loading = false));
    }
  }
  login() {
    this.loadingStart();
    this.zone.run(() => (this.loading = true));

    const options = {
      scope: this.authScope,
      responseType: "token id_token",
    };
    if (window.hasOwnProperty(CONSTANTS.CORDOVA)) {
      // Authorize login request with Auth0: open login page and get auth results
      this.Client.authorize(options, (err, authResult) => {
        this.processAuthResult(err, authResult);
      });
    } else {
      // USE WEB AUTH
      this.Auth0.authorize(
        {
          redirect_uri: this.auth_config.appRedirect, //Any additional options can go here
          ...options,
        },
        function (err, authResult) {
          // console.log("INLINE BROWSER AUTH CALLBACK", authResult);
        }
      );
    }
  } //end login()

  async presentLogin() {
    this.hideLogin();
    this.toast = await this.toastController.create({
      translucent: false,
      message: "We don&apos;t know who you are...",
      color: "primary",
      position: "middle",
      keyboardClose: false,
      buttons: [
        {
          side: "end",
          icon: "log-in",
          text: "LOG IN",
          handler: () => {
            console.log("Log In clicked");
            this.login();
          },
        },
        // , {
        //   // text: 'Dismiss',
        //   role: 'cancel',
        //   icon: 'close',
        //   handler: () => {
        //     console.log('Cancel clicked');
        //   }
        // }
      ],
    });
    this.toast.present();
  }
  async hideLogin() {
    try {
      this.toast.dismiss();
    } catch (e) {}
  }

  async loadingStart() {
    this.loading = true;
    if (!this.loadingModal) {
      this.loadingModal = await this.loadingController.create({
        message: "Please wait...",
        spinner: "crescent",
        cssClass: "fenix-spinner",
      });
      await this.loadingModal.present();
    }
  }

  loadingDone() {
    this.loading = false;
    if (this.loadingModal) {
      this.loadingModal.dismiss();
    }
  }

  async logout() {
    this.zone.run(() => (this.loading = true));
    this.loadingStart();
    await this.fcmService.unRegisterDeviceToNotifications()
      //Removing auth from local storage
      localStorage.removeItem("auth0_access_token");
      localStorage.removeItem("auth0_id_token");
      localStorage.removeItem("auth0_expires_at");
      localStorage.removeItem("auth0_profile");
      // Remove isLoggedIn flag from localStorage
      localStorage.removeItem("auth0_isLoggedIn");

      //Clean User information
      localStorage.setItem("user_org_id", "no-access");

      this.accessToken = null;
      this.user = null;
      this.loggedIn = false;

      //Sign out from Firebase
      this.afAuth.auth.signOut();
      this.loadingDone();
      const auth0Domain = this.auth_config.domain;
      const clientId = this.auth_config.clientId;
      const pkgId = this.auth_config.packageIdentifier;
      let url = `https://${auth0Domain}/v2/logout?client_id=${clientId}&returnTo=${pkgId}://${auth0Domain}/cordova/${pkgId}/callback`;
      //this.loading = false;
      // if Mobile
      if (window.hasOwnProperty(CONSTANTS.CORDOVA)) {
        this.safariViewController
          .isAvailable()
          .then((available: boolean) => {
            if (available) {
              this.safariViewController
                .show({
                  url: url,
                })
                .subscribe(
                  (result: any) => {
                    if (result.event === "opened") {
                      console.log("Opened");
                    } else if (result.event === "loaded") {
                      console.log("Loaded");
                      this.safariViewController.hide();
                      this.presentLogin();
                    } else if (result.event === "closed") {
                      console.log("Closed");
                      this.presentLogin();
                    }
                  } ,
                  (error: any) => console.error(error)
                );
            } else {
              // use fallback browser
              cordova.InAppBrowser.open(url, "_system");
            }
          })
          //  Logout from auth-cordova after logout using url.
          // .finally(() => this.Client.logout());
      } else {
        // if web logout from web auth client
        this.Auth0.logout({
          returnTo: this.auth_config.appRedirect, //'http://localhost:8100/'
        });
      }

      this.zone.run(() => (this.loading = false));
  } //end logout()
}
