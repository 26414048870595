// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
         production: false,
         firebase: {
           apiKey: "AIzaSyD8j1jgjeECB2dkYJe_FC_gZYlnj1BfX9c",
           authDomain: "fenix-feed.firebaseapp.com",
           databaseURL: "https://fenix-feed.firebaseio.com",
           projectId: "fenix-feed",
           storageBucket: "fenix-feed.appspot.com",
           messagingSenderId: "595735873100",
           appId: "1:595735873100:web:db399923f9333c946f96e9",
           vapidKey:
             "BOkbCIPlboazfIEhEKAAOB1FT3L89RPJK2YJ4HI7EMnTaVQE8uzrvVkgYUFYmzk9WLZUw70M7uoWP38e81NmWXs",
         },
         algolia: {
           searchOnlyAPIKey: "9ad1447cf529d09ecefb0db9cbcaa71e",
           appId: "6X2WLK4HWE",
           indexName: "post_marketplace_index",
         },
         fenixAllowedDeepLinkHosts: ["fenix.group"],
         fenixIgnoreDeepLinkHosts: ["link.fenix.group"],
         fenixFilesValidExtensions: ["pdf","jpg", "gif" , "png" , "ppt" , "xls" , "doc" , "pptx" , "xlsx" , "docx"],
         enableNotifications: true,
         appVersion : '3.1.1',
       };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
