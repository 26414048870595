// Change this filename to auth.config.ts

export const AUTH_CONFIG = {
  // Needed for Auth0 (capitalization: ID):
  clientID: 'CzQi-wnfi6FgjVQdeTr30oO35R7hCYrn',
  // Needed for Auth0Cordova (capitalization: Id):
  clientId: 'CzQi-wnfi6FgjVQdeTr30oO35R7hCYrn',
  client_id: 'CzQi-wnfi6FgjVQdeTr30oO35R7hCYrn',
  domain: 'go-demo.auth0.com', // e.g., you.auth0.com
  packageIdentifier: 'io.fenix.post.viewer', // config.xml widget ID
  // appRedirect: 'http://localhost:8100/',
  // redirectUri: 'http://localhost:8100',
  appRedirect: 'https://devapp.fenix.group/',
  redirectUri: 'https://devapp.fenix.group/',
  audience: 'https://go-demo.auth0.com/userinfo',
  responseType: 'token id_token',
  scope: 'openid profile email'
  //PWA Redirection
  //appRedirect: 'https://fenix-feed.firebaseapp.com/'
  //PWA.fenix.group
  //appRedirect: 'https://pwa.fenix.group/'

};
