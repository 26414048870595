import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Platform } from '@ionic/angular';
import * as _ from 'lodash';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
// Env settings
import { environment } from '../../../environments/environment';
import { AUTH_CONFIG } from '../auth.config';
import { PostFirestore, PostService } from './post.service';
import {
    AngularFirestore,
    AngularFirestoreCollection,
    DocumentReference
} from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { promise } from 'protractor';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class FenixDeepLinks {

    constructor(private inAppBrowser: InAppBrowser,
                private safariViewController: SafariViewController,
                private platform: Platform,
                private afs: AngularFirestore,
                private postService: PostService,
                private router: Router) {


    }

    openDeepLink(url) {

        this.replaceHttpToAppDeepLinkProtocol(url).then((result) => {
            if (result.isRouter) {
                this.router.navigate([result.url]);
            } else {
                this.openUrl(result.url);
            }
        });

    }

    replaceHttpToAppDeepLinkProtocol(link): Promise<any> {

        return new Promise((resolve, reject) => {
            let linkTransformend: string = link;
            let itIsARouterLink = false;

            const isIncluded = _.some(environment.fenixAllowedDeepLinkHosts, (el) => _.includes(link, el));
            const isIgnored = _.some(environment.fenixIgnoreDeepLinkHosts, (el) => _.includes(link, el));
            const isValidExt = _.some(environment.fenixFilesValidExtensions, (el) => _.includes(link, el)); 

            // console.log("isValidExt",isValidExt);

            if (isIncluded && !isIgnored && !isValidExt) {
                const linkArray = linkTransformend.trim().split('/');
                // console.log('Split:', linkArray);
                const postName = linkArray[linkArray.length - 1] === '' ? 
                        linkArray[linkArray.length - 2] : linkArray[linkArray.length - 1];
                console.log('POST_NAME : ', postName);
                this.postService.getPostByPostName(postName)
                            .toPromise().then((postLinked: PostFirestore) => {

                    //console.log(postLinked.id);

                    linkTransformend = '/detail/' + postLinked.id;
                    itIsARouterLink = true;


                    return resolve({
                        url: linkTransformend,
                        isRouter: itIsARouterLink
                    });
                }).catch(()=>{
                    return resolve({
                        url: link,
                        isRouter: false
                    })
                });

            } else {
                return resolve({
                    url: linkTransformend,
                    isRouter: itIsARouterLink
                });
            }

        });
    }

    openWebpage(link: string) {
        const opts = 'location=no,clearcache=no,clearsessioncache=no,hidespinner=no';
        this.inAppBrowser.create(link, '_blank', opts);
    }

    openUrl(url) {

        if (this.platform.is('ios')
            || this.platform.is('android')) {
            this.safariViewController.isAvailable().then(available => {
                console.log('SafariView', available);
                if (available) {
                    const safariViewSuncription = this.safariViewController.show({
                        url,
                        hidden: false
                    }).subscribe(
                        // this success handler will be invoked for the lifecycle events 'opened', 'loaded' and 'closed'
                        result => {
                            console.log(result);
                            if (result.event === 'opened') {
                                console.log('opened');
                            } else if (result.event === 'loaded') {
                                console.log('loaded');
                            } else if (result.event === 'closed') {
                                safariViewSuncription.unsubscribe();
                                console.log('closed');
                            }
                        });
                } else {
                    this.openWebpage(url);
                }
            });
        } else {
            this.openWebpage(url);
        }
    }
}
